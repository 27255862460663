import axios from "axios";

class apiCall {
  tmParamsBase() {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get("tmticx?randomadd=" + new Date().getTime());
  }
  report(report) {
    const api_url = report;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  tmStats(id) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      id +
        "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
        new Date().getTime()
    );
  }
  stat(id, stat) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      id + "/" + id + stat + ".json?randomadd=" + new Date().getTime()
    );
  }
}

export default new apiCall();